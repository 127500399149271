import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from '../routes/Routes';


export function Root() {
    return (
        <Router>
            <Routes />
        </Router>
    )
}