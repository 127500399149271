import { createGlobalStyle } from 'styled-components'


export const GlobalStyle = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing:border-box;
    }

    :root{
        --linearGradientColor: linear-gradient(90deg, rgba(11,131,200,1) 0%, rgba(189,101,223,1) 100%);
        --linearGradientColor2: linear-gradient(90deg, #00B1C6 0%, #7872E2 100%);

        --white-100: #ffffff;
        
        --blue-50:#EEF8FE;
        --blue-300: #3fa8ff;
        --blue-350: #0084F7;
        --blue-600: #3562E3;
        
        --gray-300: #333;

        --purple-200: #a6a2f2;
    }
    body {
    -webkit-font-smoothing: antialiased;
    }

  .react-router-Link{
        list-style: none;
        text-decoration: none;
        color: black;
        font-size: 1rem;
    }

    
    @media (max-width: 1080px) {
        html{
            font-size: 93.75%;
        }
    }

    @media (max-width: 720px) {
        html{
            font-size: 87.5%;
        }
    }

    body, input, textarea, select, button {
        font: 400 1rem 'Poppins', sans-serif;
    }

    button{
        cursor: pointer;
    }

    a{
        color: var(--purple-200);
        text-decoration: none;
    }

    .customInput{
        padding: 0 16px;
        border: none;
        outline: none;
        border-radius: 5px;
        background-color: var(--blue-50);
        height: 3.2rem;
        font-size: 1.25rem;
    }
`