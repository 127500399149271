import { ToastContainer } from 'react-toastify';
import { Root } from './pages/root';
import { GlobalStyle } from './styles/global';

import 'react-toastify/dist/ReactToastify.css';


function App() {

  // Initialize Firebase

  return (
    <>
      <ToastContainer autoClose={3000} />
      <GlobalStyle />
      <Root />
    </>
  );
}

export default App;
