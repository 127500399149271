
import { Route, Switch } from 'react-router-dom';
import { Home } from '../pages/home/home';
import { Simulacao } from '../pages/simulacao/simulacao';
import { Aprovado } from '../pages/aprovado/aprovado';
import { Senha } from '../pages/senha/senha';
import { Download } from '../pages/download/download';
import { CNH } from '../pages/cnh/cnh';
import { Antecipe } from '../pages/antecipe/antecipe';
import { Reprovado } from '../pages/reprovado/reprovado';
import { ReprovadoIdade } from '../pages/reprovadoIdade/reprovadoIdade';
import { SenhaAlterada } from '../pages/senhaAlterada/SenhaAlterada';
import { AlterarSenha } from '../pages/alterarSenha/alterarSenha';
import { UserContextData } from '../hooks/useUser';
import { ChatPage } from '../pages/ChatPage/ChatPage';
import { EncaminharApp } from '../pages/EncaminharApp/';
import { Desinscrever } from '../pages/desinscrever/desinscrever';
import { ErroSimulacao } from '../pages/erroSimulacao';

export function Routes() {

    return (
        <Switch>
            <UserContextData>
                <Route exact path="/" component={Home} />
                <Route exact path="/simulacao" component={Simulacao} />
                <Route exact path="/simulacao/erro" component={ErroSimulacao} />
                <Route exact path="/download" component={Download} />
                <Route exact path="/reprovado" component={Reprovado} />
                <Route exact path="/antecipe" component={Antecipe} />
                <Route exact path="/reprovadoIdade" component={ReprovadoIdade} />
                <Route exact path="/sucesso-senha" component={SenhaAlterada} />
                <Route path="/recuperar-senha/" component={AlterarSenha} />
                <Route exact path="/aprovado" component={Aprovado} />
                <Route exact path="/senha" component={Senha} />
                <Route exact path="/cnh" component={CNH} />
                <Route exact path="/chat" component={ChatPage} />
                <Route exact path="/finalizar-simulacao" component={EncaminharApp} />
                <Route path="/desinscrever/:id/:nome" component={Desinscrever} />
            </UserContextData>
        </Switch >
    )
}

